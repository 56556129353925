<template>
  <v-expansion-panels class="my-1">
    <v-expansion-panel>
      <v-card-text class="py-0">
        <v-expansion-panel-header class="px-0">
          <div class="d-flex">
            <span class="title-font align-self-center">{{
              $t('debates.summaryAI')
            }}</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="summary-wrapper">
          <div class="d-flex justify-end">
            <button
              class="disclaimer-button"
              @click.stop="showDisclaimer()"
              icon
            >
              <v-icon color="orange">mdi-information</v-icon>
            </button>
          </div>
          <div
            :class="`summary-content ${theme}`"
            :style="'white-space: pre-line;'"
            v-html="summary"
          />
        </v-expansion-panel-content>
      </v-card-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { showDialog } from '../../../helpers/dialogHelper';
import DisclaimerTextDialog from './DisclaimerTextDialog.vue';

export default {
  name: 'DocumentSummary',

  props: {
    summary: {
      type: String,
      required: true,
    },
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'darkMode' : 'lightMode';
    },
  },

  methods: {
    showDisclaimer() {
      showDialog(DisclaimerTextDialog, { theme: this.theme });
    },
  },
};
</script>

<style scoped>
.title-font {
  color: teal;
  font-weight: 600;
}

.disclaimer-button {
  height: 24px;
  width: 24px;
}

.summary-content {
  margin-top: -24px;
  &.lightMode {
    color: rgba(0, 0, 0, 0.9);
  }

  &.darkMode {
    color: rgba(255, 255, 255, 0.7);
  }
}

.v-expansion-panel-content.summary-wrapper
  >>> .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
