<template>
  <v-expansion-panels
    v-model="panel"
    multiple
  >
    <europarl-document-specs
      :document="document"
    />
    <europarl-references
      :refs="refs"
      :id="documentId"
    />
  </v-expansion-panels>
</template>

<script>
import EuroparlReferences from './EuroparlReferences.vue';
import EuroparlDocumentSpecs from './EuroparlDocumentSpecs.vue';

export default {
  name: 'eu-detail-sidebar',

  components: {
    EuroparlReferences,
    EuroparlDocumentSpecs,
  },

  props: {
    document: {
      type: Object,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      panel: [0, 1],
    };
  },

  computed: {
    refs() {
      // EUROPARL refs
      const europarl = [];
      if (this.document.reference) {
        europarl.push(this.document.reference);
      }
      if (this.document.relations) {
        this.document.relations.forEach((item) => {
          if (['PROCEDURE', 'DOSSIER'].includes(item.type)) {
            europarl.push(item.code);
          }
        });
      }

      // Return an object, as other documents can have other types of refs
      return (europarl.length === 0) ? {} : { europarl };
    },
  },
};
</script>
