<!--
  Component used to display document text with highlighted words
-->

<template>
  <div>
    <div v-if="rssDocument">
      <div
        class="document-body text--primary"
        v-html="computedQuotify"
      />
      <div>
        Bekijk gehele bericht op: <a
          :href="link"
          target="_blank"
        >{{ computedDomainify }}</a>
      </div>
    </div>

    <div v-else>
      <div
        class="document-body text--primary"
        :style="isRawHTML ? '' : 'white-space: pre-line;'"
        v-html="text"
      />
    </div>
  </div>
</template>

<script>
// import ContentPanel from '@/components/ContentPanel.vue';
import quotify from '@/helpers/quotifyHelper';
import domainify from '@/helpers/domainifyHelper';

export default {
  name: 'DocumentText',

  components: {
    // ContentPanel,
  },

  props: {
    isRawHTML: {
      type: Boolean,
      required: true,
    },
    // Document text
    text: {
      type: String,
      required: true,
    },
    // Link tot original document
    link: {
      type: String,
      required: false,
    },
    rssDocument: {
      type: Boolean,
      required: true,
    },
    sourceUrl: {
      type: String,
      required: false,
    },
  },

  computed: {
    computedQuotify() {
      return quotify(this.text);
    },

    computedDomainify() {
      return domainify(this.link);
    },
  },
};
</script>

<style lang="scss"> // Carefull, block not scoped
  .document-body {
    overflow-wrap: break-word;
    em.hlt1 {
      background-color: $color-marker;
      padding: 2px 4px;
      color: white;
      font-style: normal;
    }
    h1 {
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 500;
      padding-bottom: 10px;
      letter-spacing: 0.0125em;
      &.staatscourant_kop,
      &.staatsblad_kop,
      &.stuktitel,
      &.dossiertitel {
        font-size: .875rem;
        font-weight: 400;
        line-height: 1.375rem;
      }
    }
    span.nootnum {
      vertical-align: super;
      font-size: 0.8em;
    }
    .broodtekst-container div.plaatje {
      display: none;
    }
  }
</style>
