<template>
  <div>
    <v-dialog
      v-model="dialog"
      :class="`${data.theme}`"
      max-width="max(30%, 384px)"
      persistent
      scrollable
      @click:outside="hideDialog()"
    >
      <v-card class="py-4 px-6">
        <div class="close-icon">
          <v-icon @click="hideDialog()"> mdi-close </v-icon>
        </div>
        <div class="text-body-2 pr-3">
          <div class="d-flex justify-space-between mb-4">
            <h3 class="d-inline-block">
              Disclaimer bij automatische samenvattingen door AI
            </h3>
          </div>
          <p>
            Deze samenvatting is automatisch gegenereerd door een AI-model.
            Hoewel ernaar wordt gestreefd de tekst nauwkeurig en neutraal samen
            te vatten, kunnen er onbedoeld fouten of interpretatieverschillen
            optreden. De oorspronkelijke tekst kan bijvoorbeeld bepaalde
            nuances, een specifieke politieke kleur of impliciete voorkeuren
            bevatten, die mogelijk (onbewust) doorwerken in de samenvatting. Om
            de samenvatting zo feitelijk en neutraal mogelijk te maken, wordt
            gebruikgemaakt van zorgvuldig geformuleerde instructies (prompting).
            Dit proces richt zich op het vermijden van bevooroordeeld
            taalgebruik en benadrukt objectiviteit. Toch kan volledige
            neutraliteit niet worden gegarandeerd. Wij raden aan om, indien
            mogelijk, ook de oorspronkelijke tekst te raadplegen voor een
            volledig beeld.<br /><br />
            Feedback over de samenvatting? Geef het ons door via de in-tool
            chat.
          </p>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { hideDialog } from '@/helpers/dialogHelper';

export default {
  name: 'DisclaimerTextDialog',

  props: {
    data: {
      type: Object,
      default() {
        return {
          theme: '',
        };
      },
    },
  },

  data() {
    return {
      dialog: true,
    };
  },

  methods: {
    hideDialog,
  },
};
</script>
<style scoped>
.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
