const typeToColor = (type) => {
  // Avoid crashes in case something is misconfigured. We use lighten-2 here to help identify any such issues.
  if (!type) return 'grey lighten-2';
  // Institutions are processed on 'institution', so `type` is a misnomer here
  if (type === 'EUROCOM') return 'amber accent-2';
  if (type === 'EUROPARL') return 'blue darken-4';
  if (type === 'EUROCOU') return '#FF914D';

  // For all others, actually use `type`:
  if (type === 'SB-EU-Source-4') return '#FF914D'; // Consilium press releases
  if (type.startsWith('DH-')) return '#2962FF';
  if (type.startsWith('DS-')) return '#00BFA5';
  if (type.startsWith('DS2-')) return '#00BFA5';
  if (type.startsWith('DM-')) return 'brown lighten-1';
  if (type.startsWith('SB-')) return 'grey lighten-1';
  if (type.startsWith('MD-')) return 'purple lighten-1';
  if (type.startsWith('STB-')) return '#2EA3F2';
  if (type.startsWith('DOJ-')) return '#F09834';
  if (type.startsWith('CC-')) return 'cyan darken-3';
  if (type.startsWith('PC-')) return 'light-blue darken-4';
  // Catch-all for everything else
  return 'grey lighten-1';
};

export default typeToColor;
