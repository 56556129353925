<!--
  Component which displays a list of subjects
-->

<template>
  <v-expansion-panel class="">
    <v-expansion-panel-header>
      {{ $t('generic.topics')}}
      <div
        class="text-right pr-4"
      >
        <v-btn
          icon
          @click.stop.prevent="toggleSubjectsView"
        >
          <v-icon
            small
            v-if="subjectsCloud"
          >
            <!-- mdi-vector-arrange-below -->
            mdi-format-list-bulleted
          </v-icon>
          <v-icon
            small
            v-if="!subjectsCloud"
          >
            <!-- mdi-vector-arrange-above -->
            mdi-cloud-outline
          </v-icon>
        </v-btn>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <vue-word-cloud
        v-if="subjectsCloud"
        style="height: 120px;"
        class="mb-4"
        :words="computedWords"
        :color="([, weight]) => weight > median ? '#303F9F' : '#7986CB'"
        font-family="Roboto"
        :font-size-ratio="1.4"
        :spacing="0.3"
        :weight="1"
        font-weight="500"
      />
      <ul
        v-if="!subjectsCloud"
        class="text-body-2 plain-li text--secondary"
      >
        <li
          v-for="(subject, index) in subjects"
          :key="index"
        >
          {{ subject.keyword }}
        </li>
      </ul>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import VueWordCloud from 'vuewordcloud';

export default {
  name: 'ListSubjects',

  components: {
    VueWordCloud,
  },

  props: {
    // List of subjects
    subjects: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      subjectsCloud: true,
    };
  },

  computed: {
    computedWords() {
      const results = [];
      this.subjects.forEach((item) => {
        results.push([item.keyword, item.count]);
      });

      return results;
    },

    median() {
      // This finds the median in an array of counts so we make sure
      // there are always two colors used.
      const arr = this.subjects.map(({ count }) => count).sort((a, b) => a - b);
      const mid = Math.floor(arr.length / 2);
      return arr.length % 2 !== 0 ? arr[mid] : (arr[mid - 1] + arr[mid]) / 2;
    },
  },

  methods: {
    toggleSubjectsView() {
      this.subjectsCloud = !this.subjectsCloud;
    },
  },
};
</script>
