const cleanReferenceNumber = (ref) => {
  let cleanedRef = ref;
  // Clean up preceding COM_ if it exists.
  // For example: COM_COM(2021)0012 -> COM(2021)0012
  if (ref.startsWith('COM_')) {
    cleanedRef = ref.slice(4);
  }
  // Clean up leading zeroes and spaces in the number at the end,
  // and replaces them with a single space. For example:
  // - COM(2021)0012 -> COM(2021) 12
  // - COM(2021)1234 -> COM(2021) 1234
  // - COM(2021) 123 -> COM(2021) 123 (unchanged)
  const firstHalf = cleanedRef.slice(0, cleanedRef.indexOf(')') + 1);
  const secondHalf = cleanedRef.slice(cleanedRef.indexOf(')') + 1).trim();
  cleanedRef = `${firstHalf} ${secondHalf.replace(/^0+/, '')}`;
  // TODO: more cleaning here?
  return cleanedRef;
};

export default cleanReferenceNumber;
