// Helper function to use the store in a vuex 4.0.0 like way
// See https://github.com/vuejs/vuex/issues/2212
import { getCurrentInstance } from 'vue';

// We leave out the export default on purpose over here to
// make it compatible with future upgrade to Vue3
// eslint-disable-next-line import/prefer-default-export
export const useStore = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$store;
};
