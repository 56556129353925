<template>
  <v-card
    class="pdfPage my-1"
    ref="page"
    data-rendered="false"
  >
    <!-- <div class="annotationLayer"></div> -->
    <div class="textLayer" ref="textLayer"></div>
    <div
      v-show="loading"
      class="spinner"
      ref="spinner"
    >
      <v-progress-circular
        indeterminate
        class="mr-4"
      ></v-progress-circular>
    </div>
    <!-- canvas dynamically created in code -->
  </v-card>
</template>

<script>
import { addContextCurrentTransform } from '@/helpers/firefoxCanvasContextTransformHelper';

const pdfjsLib = require('pdfjs-dist/build/pdf');

export default {
  props: {
    pageNum: {
      type: Number,
      required: true,
    },
    pageDataPromise: {
      type: Promise,
      required: true,
    },
    zoomScale: {
      type: Number,
      required: true,
    },
    viewerWidth: {
      type: Number,
      required: true,
    },
    keywords: {
      type: Array,
      required: false,
    },
    scopedAttribute: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      loading: true,
      canvasDimentionsSet: false,
      bookmarksSet: false,
      pageData: null,
      viewport: null,
      elemWidth: null,
      elemHeight: null,
    };
  },

  methods: {
    async setCanvasDimentions() {
      // Generate a new canvasElem when we (re)render to prevent issues with double render in pdf.js;
      const oldCanvas = this.$refs.page.$el.querySelector('canvas');
      const canvas = document.createElement('canvas');
      canvas.classList.add('pdfCanvas');
      if (this.$vuetify.theme.dark) canvas.classList.add('darkMode');
      if (oldCanvas) {
        // Copy css size as to minimise flickering
        canvas.style.width = oldCanvas.style.width;
        canvas.style.height = oldCanvas.style.width;
        // remove old canvas
        oldCanvas.remove();
      }
      this.$refs.page.$el.appendChild(canvas);

      // Get canvas context
      this.ctx = canvas.getContext('2d');
      // Ugly hack to make the canvas rendering by PDF.js work properly in Chromium based browsers on the first try.
      // Otherwise the first render seems to wrongly render the position/scale of certain images on chromium browsers.
      addContextCurrentTransform(this.ctx);

      this.pageData = await this.pageDataPromise;

      // Set the css width and height of the canvas
      // So there is minimal content jumping/layout shifting later.
      // Looks weird to call getViewport twice but this is
      // suggested method by PDF.js documentation
      const scaleViewport = this.pageData.getViewport({ scale: this.zoomScale });
      this.viewport = this.pageData.getViewport({ scale: this.viewerWidth / scaleViewport.width });
      const pageHeightWidthRatio = this.viewport.height / this.viewport.width;

      this.elemWidth = this.viewerWidth / this.zoomScale;
      this.elemHeight = (this.viewerWidth * pageHeightWidthRatio) / this.zoomScale;

      canvas.style.width = `${this.elemWidth}px`;
      canvas.style.height = `${this.elemHeight}px`;

      const { spinner } = this.$refs;
      spinner.style.width = `${this.elemWidth}px`;
      spinner.style.height = `${this.elemHeight}px`;

      this.canvasDimentionsSet = true;
    },

    async setBookmarks() {
      if (this.bookmarksSet) return;
      this.textContent = await this.pageData.getTextContent();
      if (this.keywords && this.keywords.length > 0) {
        const bookmarkKeywords = {};

        this.textContent.items.forEach((item) => {
          this.keywords.forEach((keyword) => {
            const matches = this.keywordRegexMatches(item.str, keyword);
            if (matches && matches.length > 0) {
              if (!bookmarkKeywords[keyword]) bookmarkKeywords[keyword] = 0;
              bookmarkKeywords[keyword] += matches.length;
            }
          });
        });

        if (Object.keys(bookmarkKeywords).length > 0) {
          this.$emit('pageReady', this.pageNum, this.$refs.page.$el, bookmarkKeywords);
        }
      }

      this.bookmarksSet = true;
    },

    async render() {
      if (!this.canvasDimentionsSet) await this.setCanvasDimentions();
      if (!this.bookmarksSet) await this.setBookmarks();

      const canvas = this.$refs.page.$el.querySelector('canvas');

      // Set the internal canvas resolution
      const { devicePixelRatio } = window; // Is 1 on larger displays
      canvas.width = Math.floor(this.elemWidth * devicePixelRatio);
      canvas.height = Math.floor(this.elemHeight * devicePixelRatio);

      await this.renderCanvasLayer();
      await this.renderTextLayer();
      this.$refs.page.$el.dataset.rendered = true;
      this.loading = false;
    },

    // Render the PDF visuals to the canvas.
    async renderCanvasLayer() {
      // Scale the canvas pixel size
      this.ctx.resetTransform();
      this.ctx.scale(devicePixelRatio, devicePixelRatio);
      await this.pageData.render({
        canvasContext: this.ctx,
        viewport: this.viewport,
      }).promise;
    },

    // Add text layer to make text selectable and searchable with ctrl+f
    async renderTextLayer() {
      const textLayerElem = this.$refs.textLayer;
      textLayerElem.innerHTML = ''; // Clear if it already has a layer
      await pdfjsLib.renderTextLayer({
        textContent: this.textContent,
        container: textLayerElem,
        viewport: this.viewport,
      }).promise;

      // Apply the scoped css styling to the dynamically generated elements under the textLayer:
      [this.$refs.textLayer, ...this.$refs.textLayer.children].forEach(
        (elem) => elem.setAttribute(this.scopedAttribute, ''),
      );

      if (this.keywords && this.keywords.length > 0) await this.highlightKeywords();
    },

    // Highlight keywords (searchTerms)
    async highlightKeywords() {
      const textSpans = this.$refs.textLayer.querySelectorAll('span');
      Array.from(textSpans).forEach((textSpan) => {
        let text = textSpan.innerText;

        // Find the indexes where matches with keywords start and end
        const matchStartIndexes = [];
        const matchEndIndexes = [];
        this.keywords.forEach((keyword) => {
          const matches = this.keywordRegexMatches(text, keyword);
          matches.forEach((match) => {
            matchStartIndexes.push(match.index);
            matchEndIndexes.push(match.index + keyword.length);
          });
        });

        // Insert <em> tags around keywords without overlap.
        const startTag = '<em class="hlt1 pdfHlt">';
        const endTag = '</em>';
        // We loop through the test string backwards as we are inserting text
        // which messes up the loop indexing if we go forward.
        // Note: We loop from text.length till (and including) 0,
        // this because we might have to insert at the very end.
        let runningKeywords = 0;
        for (let i = text.length; i >= 0; i--) {
          const oldRunningKeywords = runningKeywords;
          const endingKeywords = matchEndIndexes.filter((n) => n === i).length;
          const startingKeywords = matchStartIndexes.filter((n) => n === i).length;
          runningKeywords += endingKeywords - startingKeywords; // end = +, and start = - because we go backwards
          if (oldRunningKeywords === 0 && runningKeywords > 0) {
            text = `${text.substring(0, i)}${endTag}${text.substring(i)}`;
          } else if (oldRunningKeywords > 0 && runningKeywords === 0) {
            text = `${text.substring(0, i)}${startTag}${text.substring(i)}`;
          }
        }
        textSpan.innerHTML = text;
      });
    },

    keywordRegexMatches(str, keyword) {
      const escapedKeyword = keyword && keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // escape
      return Array.from(str.matchAll(new RegExp(`\\b${escapedKeyword}\\b`, 'ig'))); // `\b` matches word boundry
    },
  },
};
</script>

<style scoped lang="scss">
  .pdfPage {
    position: relative; // Required for the textLayer to overlap the canvas correctly
    width: fit-content;
    margin: 0 auto;
    overflow-x: auto;

    // Ensures that scrollInotView doesn't put the top of the page under the polpo header.
    scroll-margin-top: 7.6rem; // estimate

    .textLayer {
      position: absolute;
      z-index: 1; // Required to select text (if canvas has class `darkMode`).
      opacity: 0.5;
      overflow: clip; // Prevents scrollIntoView from scrolling to overflow,
                      // and causing misalignment

      ::v-deep em.hlt1 {
        background-color: #2196F3;
      }
    }

    .spinner {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ::v-deep .pdfCanvas { // ::v-deep required as canvas is not rended by Vue
      &.darkMode {
        filter: grayscale(100%) invert(0.9); // Invert colors
      }
    }
  }
</style>
