<template>
  <div
    :class="`audioTextFragment ${this.$vuetify.theme.dark ? 'darkMode' : 'lightMode'}`"
  >
    <span
      class="text-fragment"
      :id="chunk._id"
      @click.prevent="sendPlayAudioChunk"
      v-html="chunk.text"
    />
  </div>
</template>

<script>

export default {
  props: {
    chunk: {
      type: Object,
      default: null,
      required: true,
    },
  },

  computed: {
    startingTimestamp() {
      return this.chunk.timestamps[0].$numberDecimal;
    },
  },

  methods: {
    sendPlayAudioChunk() {
      this.$emit('playAudioChunk', this.startingTimestamp);
    },
  },
};
</script>

<style scoped lang="scss">
span {
  display: inline-block;
  cursor: pointer;
}

.audioTextFragment {
  ::v-deep em.hlt1 {
    background-color: $color-marker;
    padding: 2px 4px;
    color: white;
    font-style: normal;
  }

  .currentAudioLine {
    font-weight: 700;
  }

  &.lightMode {
    .pastAudioLine {
      color: rgba(0, 0, 0, 0.9);
    }
    .currentAudioLine {
      color: #007a6e;
    }
  }

  &.darkMode {
    .pastAudioLine {
      color: rgba(255, 255, 255, 0.9);
    }
    .currentAudioLine {
      color: #00ccb7;
    }
  }
}
</style>
